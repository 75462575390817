<template>
  <a-card :bordered="false">
    <template slot="title">

      <a-row align="middle" justify="space-between" type="flex">
        <a-col>
          <span style="font-weight: bolder; font-size: 20px;">数据告警</span>
        </a-col>

        <a-col>
          <a-date-picker v-model="query.time" @change="customQuery"/>
        </a-col>
      </a-row>
    </template>

    <a-table :columns="columns" :data-source="data" :loading="loading"
             :pagination="pagination" @change="handleTableChange">
      <span slot="represent" slot-scope="text">
        <a-badge :text="text" status="error"/>
      </span>

      <span slot="trashType" slot-scope="text">
          <a-tag :color="trashType[text].color">{{ trashType[text].name }}</a-tag>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import {trashType} from '@/common/config'
import {queryVillageDataException} from "@/api/village";
import {formatToDateBegin, formatToDateEnd} from "@/utils/time";
import _ from "lodash";
import moment from "moment";

export default {
  name: "dataAlarm",
  data() {
    return {
      trashType,
      query: {
        time: undefined
      },
      pagination: {
        pageSize: 2,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      columns: [
        {
          title: '小区',
          dataIndex: 'centerName',
          key: 'centerName',
          align: 'center'
        },
        // {
        //   title: '垃圾类型',
        //   dataIndex: 'trashType',
        //   key: 'trashType',
        //   scopedSlots: {customRender: 'trashType'},
        //   align: 'center'
        // },
        {
          title: '异常描述',
          dataIndex: 'represent',
          key: 'represent',
          scopedSlots: {customRender: 'represent'},
          align: 'center'
        },
        {
          title: '发生时间',
          dataIndex: 'exceptionTime',
          key: 'exceptionTime',
          scopedSlots: {customRender: 'exceptionTime'},
          align: 'center'
        },
        // {
        //   title: '重量（KG）',
        //   key: 'weight',
        //   dataIndex: 'weight',
        //   align: 'center'
        // },
        // {
        //   title: '发生日期',
        //   key: 'happenedAt',
        //   dataIndex: 'happenedAt',
        //   align: 'center'
        // },
      ],
      data: [],
      loading: true,
    }
  },
  mounted() {
    this.query.time = moment().subtract(1, 'days').format('YYYY-MM-DD');
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current

      this.queryDataAlarmList()
    },
    async queryDataAlarmList() {
      this.loading = true

      let data = await queryVillageDataException(this.convertQuery())

      _.forEach(data.result.content, (value) => {
        value.exceptionTime = moment(value.exceptionTime).format('YYYY-MM-DD HH:mm:ss')
      })
      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    convertQuery() {
      return {
        startTime: formatToDateBegin(this.query.time),

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        // sort: this.query.sort,
        // order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (value) => {
        return {
          key: value.id,
          ...value
        }
      })
    },
    customQuery() {
      this.queryDataAlarmList()
      this.$emit('changeQuery', this.query)
    },
  }
}
</script>

<style scoped>

</style>
