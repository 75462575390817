<template>
  <a-card :bordered="false">
    <template slot="title">
      <a-space>
        <span style="font-weight: bolder; font-size: 20px;">小区接入情况</span>
               <!-- <a-date-picker v-model="query.time" @change="customQuery"/> -->
      </a-space>
    </template>

    <a-spin :spinning="loading">
      <p>
        接入数据的小区
        <span style="font-size: 30px; font-weight: bolder; padding: 24px;">
        <countTo :duration='1000' :endVal='joinCount' :startVal='0'></countTo>
      </span>
        个
      </p>
      <p>
        无数据的小区
        <span style="font-size: 30px; font-weight: bolder; padding: 24px;">
         <countTo :duration='1000' :endVal='unJoinCount' :startVal='0'></countTo>
      </span>
        个
      </p>

      <a-button @click="showNoDataVillage">查看无数据小区</a-button>
    </a-spin>

    <no-data-village ref="noDataVillage"/>
  </a-card>
</template>

<script>
import countTo from 'vue-count-to';
import {queryDataCount} from "@/api/home";
import moment from "moment";
import noDataVillage from "../../village/components/noDataVillage"

export default {
  name: "communityJoin",
  components: {countTo, noDataVillage},
  data() {
    return {
      joinCount: 0,
      unJoinCount: 0,
      loading: true,
      query: {
        time: undefined
      },
    }
  },
  mounted() {
    this.query.time = moment().subtract(1, "days")
  },
  methods: {
    async queryCommunityJoinCondition() {
      this.loading = true

      let data = await queryDataCount({
        time: this.query.time.toDate().getTime(),
      })
      this.joinCount = data.result.total
      this.unJoinCount = data.result.hasNoData

      this.loading = false
    },
    customQuery() {
      this.queryCommunityJoinCondition()
    },
    showNoDataVillage() {
      this.$refs.noDataVillage.toggleVisible()
    },
  }
}
</script>

<style scoped>

</style>
