<template>
  <a-modal :visible="visible" title="无数据小区" width="60%"
           @cancel="handleCancel" @ok="handleOk">
    <a-form layout="inline">
      <a-form-item label="选择街道">
        <a-select
            v-model="query.streetCode"
            placeholder="请选择街道"
            style="width: 200px;"
        >
          <a-select-option :key="-1" :value="undefined">全部</a-select-option>
          <a-select-option v-for="street in streetList" :key="street.value" :value="street.value">
            {{ street.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="小区名称">
        <a-input v-model="query.centerName" placeholder="请输入小区名称"/>
      </a-form-item>

      <a-form-item label="时间">
        <a-range-picker v-model="query.time"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
      </a-form-item>

      <a-form-item style="color: red; font-weight: bolder;">
        默认查询前一天！ 且当天不计入
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
             style="margin-top: 12px;"
             @change="handleTableChange">
    </a-table>
  </a-modal>
</template>

<script>
import {queryRegionNodeChild} from "@/api/region";
import _ from "lodash";
import {queryNoDataVillage} from "@/api/village";
import {formatToDate, formatToDateBegin, formatToDateEnd, formatToDateWithTime, getPreviewDate} from "@/utils/time";
import {mapState} from "vuex";

export default {
  name: "noDataVillage",
  data() {
    return {
      visible: false,
      query: {
        streetCode: undefined,
        centerName: undefined,
        time: [],

        sort: undefined,
        order: undefined
      },
      pagination: {
        pageSize: 5,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      columns: [
        {
          title: '街道名称',
          dataIndex: 'streetName',
          key: 'streetName',
          scopedSlots: {customRender: 'streetName'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '小区名称',
          dataIndex: 'centerName',
          key: 'centerName',
          scopedSlots: {customRender: 'centerName'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '未上传数据时间',
          dataIndex: 'noDateTime',
          key: 'noDateTime',
          scopedSlots: {customRender: 'noDateTime'},
          align: 'center',
          // sorter: true,
        },
      ],
      data: [],
      loading: false,
    }
  },
  mounted() {
    this.query.time = getPreviewDate(1, 1)
    this.queryVillageList()
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryVillageList()
    },
    customQuery() {
      this.pagination.current = 1
      this.queryVillageList()
    },
    async queryVillageList() {
      this.loading = true

      let data = await queryNoDataVillage(this.convertQuery())

      this.convertPagination(data.result)
      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, value => {
        return {
          ...value,
          key: value.id,
          noDateTime: formatToDate(value.date),
        }
      })
    },
    convertQuery() {
      return {
        streetCode: this.query.streetCode,
        centerName: this.query.centerName,
        startTime: formatToDateBegin(this.query.time[0]),
        endTime: formatToDateBegin(this.query.time[1]),

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
  },
  computed: mapState({
    streetList: state => state.user.streetList
  })
}
</script>

<style scoped>

</style>
