<template>
  <a-card :bordered="false">
    <template slot="title">
      <a-row align="middle" justify="space-between" type="flex">
        <a-col :span="4">
          <span style="font-weight: bolder; font-size: 20px;">热力图（近十天）</span>
        </a-col>
        <a-col :span="12">
          <a-form layout="inline">
            <a-form-item label="">
              <a-range-picker v-model="query.time" :allowClear="false"
                              :disabled-date="disabledDate"
                              @calendarChange="calendarChange"
                              @change="handleQueryDateRangeChange" @openChange="handleOpenChange"/>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </template>

    <a-spin :spinning="loading">
      <div id="homeHeatmap" style="height: 300px;"></div>
    </a-spin>
  </a-card>
</template>

<script>
import {Chart} from '@antv/g2';
import moment from "moment";
import {Heatmap} from '@antv/g2plot';
import {queryMonthTrash, queryTenDay} from "@/api/home";
import _ from "lodash";
import {trashType} from "@/common/config";
import {parseNumberWith2Fixed} from "@/utils/transition";
import {formatToDate, formatToDateBegin, formatToDateEnd, getPreviewDate} from "@/utils/time";

export default {
  name: "heatmap",
  data() {
    return {
      query: {
        time: []
      },
      data: [],
      chart: null,
      loading: true,
    }
  },
  mounted() {
    this.query.time = getPreviewDate(10, 0)

    this.queryWeightHeatmap()
  },
  methods: {
    disabledDate(current) {
      if (this.query.time[0])
        return current && (current <= this.query.time[0].clone().subtract(10, 'days') || current >= this.query.time[0].clone().add(10, 'days'));
    },
    calendarChange(dates) {
      this.query.time = dates
    },
    handleOpenChange() {
      this.query.time = []
    },
    handleQueryDateRangeChange() {
      this.queryWeightHeatmap()
    },
    async queryWeightHeatmap() {
      this.loading = true

      if (!this.query || !this.query.time || this.query.time.length < 2) {
        this.query = {
          time: getPreviewDate(10, 0)
        }
      }

      let data = await queryTenDay({
        beginDate: formatToDateBegin(this.query.time[0]),
        endDate: formatToDateEnd(this.query.time[1]),
      })

      let days = moment.duration(this.query.time[1].diff(this.query.time[0])).asDays()

      const timeList = ['06', '07', '08', '09', '16', '17', '18', '19']
      this.data = _.flatten(_.map(_.range(0, days + 1), day => {
        return _.map(_.range(0, 8), (time) => {
          let date = formatToDate(this.query.time[0], day)
          return {
            day: date,
            time: time,
            weight: parseNumberWith2Fixed(data[date + ` ${timeList[time]}`] && data[date + ` ${timeList[time]}`].weight)
          }
        })
      }))

      this.createWeightHeatmap()

      this.loading = false
    },
    createWeightHeatmap() {
      if (this.chart) {
        this.chart.update({
          data: this.data,
        })
        return
      }

      this.chart = new Heatmap(document.getElementById('homeHeatmap'), {
        autoFit: true,
        data: this.data,
        xField: 'day',
        yField: 'time',
        colorField: 'weight',
        color: ['#6495ED', '#FFFF00', '#BE002F'],
        meta: {
          'day': {
            alias: '日期',
            type: 'cat',
          },
          'weight': {
            alias: '重量',
            nice: true,
          },
          'time': {
            alias: '时间',
            type: 'cat',
            values: ['6:00-7:00', '7:00-8:00', '8:00-9:00', '9:00-10:00', '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00'],
          }
        },
        heatmapStyle: {
          lineWidth: 1,
          stroke: '#fff',
        },
        tooltip: {
          fields: ['weight', 'day', 'time'],
          showMarkers: false,
        },
        label: {
          style: {
            fill: '#fff',
            shadowBlur: 2,
            shadowColor: 'rgba(0, 0, 0, .45)',
          },
        },
        xAxis: {
          tickLine: {},
          label: {
            rotate: 0.4,
            offset: 30,
          }
        },
        legend: true,
      });
      this.chart.render();
    }
  },
}
</script>

<style scoped>

</style>
