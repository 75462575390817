export function parseNumberWith2Fixed(num) {
    try {
        return parseFloat((num).toFixed(2))
    } catch (e) {
        return 0
    }
}

export function fromNumberToPercentage(num) {
    return parseNumberWith2Fixed(num * 100)
}

