<template>
  <a-card :bordered="false">
    <template slot="title">
      <a-row align="middle" justify="space-between" type="flex">
        <a-col :span="4">
          <span style="font-weight: bolder; font-size: 20px;">趋势图</span>
        </a-col>
        <a-col :span="12">
          <a-form layout="inline">
            <a-form-item label="">
              <a-range-picker v-model="query.time" :allowClear="false" :disabled-date="disabledDate"
                              @calendarChange="calendarChange"
                              @change="handleQueryDateRangeChange" @openChange="handleOpenChange"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </template>

    <a-spin :spinning="loading">
      <div id="homeCurveLine" style="height: 300px;"></div>
    </a-spin>
  </a-card>
</template>

<script>
import {Chart} from '@antv/g2';
import moment from "moment";
import {trashType, trashName} from "@/common/config";
import {Line} from '@antv/g2plot';
import {queryMonthTrash, queryTrashCollectSum} from "@/api/home";
import _ from 'lodash'
import {parseNumberWith2Fixed} from "@/utils/transition";
import {formatToDate, formatToDateBegin, formatToDateEnd, getPreviewDate} from "@/utils/time";

export default {
  name: "curveLine",

  data() {
    return {
      query: {
        time: [],
      },
      data: [],
      chart: null,
      loading: true,
    }
  },
  mounted() {
    this.query.time = getPreviewDate(31, 0)

    this.queryWeightData()
  },
  methods: {
    disabledDate(current) {
      if (this.query.time[0])
        return current && (current <= this.query.time[0].clone().subtract(31, 'days') || current >= this.query.time[0].clone().add(31, 'days'));
    },
    calendarChange(dates) {
      this.query.time = dates
    },
    handleQueryDateRangeChange() {
      this.queryWeightData()
    },
    handleOpenChange() {
      this.query.time = []
    },
    async queryWeightData() {
      this.loading = true

      if (!this.query || !this.query.time || this.query.time.length < 2) {
        this.query = {
          time: getPreviewDate(31, 0)
        }
      }

      let data = await queryMonthTrash({
        beginDate: formatToDateBegin(this.query.time[0]),
        endDate: formatToDateEnd(this.query.time[1]),
      })

      let days = moment.duration(this.query.time[1].diff(this.query.time[0])).asDays()

      this.data = _.flatten(_.map(_.range(0, days + 1), day => {
        return _.map(trashType, (trash, index) => {
          let date = formatToDate(this.query.time[0], day)
          return {
            day: date,
            trash: trash.name,
            weight: parseNumberWith2Fixed(data[date] && data[date][`${trash.eng}Sum`])
          }
        })
      }))

      this.createWeightCurveLine()

      this.loading = false
    },
    createWeightCurveLine() {
      if (this.chart) {
        this.chart.update({
          data: this.data,
        })
        return
      }

      this.chart = new Line(document.getElementById('homeCurveLine'), {
        autoFit: true,
        data: this.data,
        xField: 'day',
        yField: 'weight',
        seriesField: 'trash',
        meta: {
          day: {
            alias: '日期',
          },
          trash: {
            alias: '垃圾',
          },
          weight: {
            alias: '重量',
          }
        },
        legend: {
          layout: 'horizontal',
          position: 'bottom'
        },
        smooth: true,
        yAxis: {
          label: {
            formatter: (weight) => {
              return weight + ' KG';
            },
          }
        },
        color: ({trash}) => {
          try {
            return trashName[trash].color
          } catch (e) {
            return "black"
          }
        },
      });
      this.chart.render();
    }
  },
}
</script>

<style scoped>

</style>
